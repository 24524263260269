import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: theme.palette.foreground.default,
    "&:hover": {
        fill: theme.palette.primary.main,
    },
    transition: "all 0.5s ease",
  },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="-35 -10 220 220"
      className={classes.svgHover}      >
     <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_5" data-name="Layer 5">
     <g transform="translate(-65,240) scale(0.18,-0.18)"
     fill="#000000" stroke="none">
     <path transform="scale(0.3)" d="M3607 4416 c-20 -7 -271 -149 -558 -316 -461 -267 -523 -300 -539
     -288 -10 7 -33 25 -51 39 -56 43 -900 530 -947 547 -72 24 -119 12 -250 -63
     -151 -87 -178 -107 -205 -158 l-22 -42 0 -1284 0 -1284 160 -93 c88 -51 164
     -93 168 -94 5 0 55 27 113 61 l104 61 0 259 c0 142 2 259 4 259 6 0 588 -340
     603 -352 10 -8 13 -104 15 -395 l3 -385 160 -93 c108 -63 168 -93 185 -91 14
     2 95 44 180 94 l155 90 3 116 c1 64 5 116 8 116 3 0 36 -17 72 -37 56 -31 76
     -38 122 -37 66 1 53 -5 414 202 195 112 308 184 347 220 105 96 204 276 230
     417 6 32 9 237 7 502 -3 447 -3 448 -26 496 -20 40 -37 55 -103 95 -43 26 -78
     51 -79 56 0 4 13 23 29 42 46 52 108 162 143 255 l33 84 0 370 c0 471 17 429
     -228 570 -130 75 -180 87 -250 61z m-74 -1347 c-10 -9 -611 -359 -629 -366
     -12 -4 -14 52 -14 329 l0 334 323 187 322 188 3 -333 c1 -182 -1 -335 -5 -339z
     m-1638 474 l305 -177 0 -528 c0 -290 -2 -528 -4 -528 -5 0 -590 341 -603 352
     -10 8 -13 127 -13 534 0 288 2 524 5 524 4 0 143 -80 310 -177z m1638 -1634
     c-10 -10 -610 -358 -629 -366 -12 -4 -14 35 -12 253 l3 258 320 186 320 185 3
     -254 c1 -140 -1 -258 -5 -262z"/>
     </g>
     </g>
     </g>
     </svg>
    );
};
