/* eslint-disable no-unused-vars */
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import { FirstName, LastName } from "../../utils/getName";
import './About.css';
import profile from '../../assets/profile.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const About = () => {
  const classes = useStyles();
  const greetings = "Hello there!";
  const aboutme = `Je suis ${FirstName} ${LastName}, avec plus de 5 ans d'expérience dans la conception et le développement d'applications web, je me spécialise dans l'utilisation de React, Node.js et TypeScript. En tant qu'indépendant, je suis heureux de mettre mes compétences au service de vos équipes, pour vous aider à concrétiser vos projets.
Avec l'expérience utilisateur au cœur de mes préoccupations, j'ai développé un fort intérêt pour le design UX/UI. Afin de fournir un code propre et facilement maintenable, j'ai choisi de me spécialiser en TypeScript..`;

  return (
    <section id="about">
      <Container component="main" className={classes.main} maxWidth="md">
        <div className="about">
          <div className="_img"
            style={{ 
              background: "url(" + profile + ")",
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
          </div>
          <div className="_content_wrapper">
            <Typography component='h2' variant="h5">
              <TextDecrypt text={`${greetings}`} />
            </Typography>
            <p className="aboutme">
              {aboutme}
            </p>
            <a href="#contact" className="contact-btn">
              <i className="fas fa-terminal"></i>
              <Typography component='span'> Contactez moi par message.</Typography>
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};
