/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import './Works.css';
// Import ../../assets/recentprojects/
import Cil4sys from '../../assets/recentprojects/Cil4sys.png';
import ChaineSainteTherese from '../../assets/recentprojects/ChaineSainteTherese.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    { 
      id: 1,
      title: 'Cil4sys Engineering - Développeur Réact', 
      description: `- Migration totale du simulateur web en react, <br>
      - Développement et ajout de nouvelles fonctionnalités au simulateur, <br> 
      - Intégration de la bibliothèque vis.js pour une timeline défilante, <br>
      - Homogénéisation du style et mise en place d'une chartre graphique`,
      alter: 'React Portfolio',
      image: `${Cil4sys}`,
    },
    { 
      id: 2,
      title: 'Chaine Sainte Thérèse', 
      description: `Projet associatif pour une chaine de prière pour les chômeurs. <br>
      Challenge pour le référencement et SEO`,
      alter: 'Chaine Sainte Thérèse',
      image: `${ChaineSainteTherese}`,
    },
    // { 
    //   id: 3,
    //   title: 'LoFo Project', 
    //   description: `Logistics and Forwarding website built using
    //   ReactJS to design and develop its front-end.`,
    //   alter: 'LoFo Project',
    //   image: `${Lofo}`,
    // },
    // { 
    //   id: 4,
    //   title: 'Startup Project', 
    //   description: `A website portfolio project for the Startup Dev Team
    //   built using MEVN stack to demonstrate the CRUD capabilities of the tech stack.`,
    //   alter: 'Startup Project',
    //   image: `${Startup}`,
    // },
    // { 
    //   id: 5,
    //   title: 'LaCalle Cafe', 
    //   description: `A website project for the La Calle Cafe business
    //   built using Wordpress and PHP with integrated SEO tools to help
    //   the business ramp up its prospects and lead generation.`,
    //   alter: 'Startup Project',
    //   image: `${Lacalle}`,
    // },
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={ project.id }>
            <div className="__img_wrapper">
              <img src={ project.image } alt={ project.alter }/>
            </div>
            <div className="__content_wrapper">
              <h3 className="title">
                <TextDecrypt text={ project.id + '. ' + project.title } />
              </h3>
              <p className="description" dangerouslySetInnerHTML={{ __html: project.description }}></p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
